import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import set from 'lodash/set';
import type { DisplayName, Locale } from './constants';
import type { AppContext, BaseAppContext } from './index';

export const { reducer, actions: AppContextActions } = createSlice({
  name: 'APP_CONTEXT',
  initialState: null as BaseAppContext | null,
  reducers: {
    initialize: (state, action: PayloadAction<AppContext>) => action.payload,
    setLocale: (state, action: PayloadAction<Locale>) => {
      if (state === null) {
        throw new Error(
          'AppContext was undefined when setting new locale. AppContext should be defined as soon as the application is starting.'
        );
      }

      state.locale = action.payload;
    },
    setDisplayName: (state, action: PayloadAction<DisplayName>) => {
      if (state === null) {
        throw new Error(
          'AppContext was undefined when setting display name. AppContext should be defined as soon as the application is starting.'
        );
      }

      state.displayName = action.payload;
    },
    setFeatureToggle: {
      prepare: (featurePath: string, active: boolean | string) => ({ payload: { featurePath, active } }),
      reducer: (state, action: PayloadAction<{ featurePath: string; active: boolean | string }>) => {
        if (state === null) {
          throw new Error(
            'AppContext was undefined when setting a feature toggle. AppContext should be defined as soon as the application is starting.'
          );
        }

        set(state.features, action.payload.featurePath, action.payload.active);
      },
    },
  },
});

interface State {
  // for compatibility/migration reasons this defines both possible contexts: market specific and market agnostic version of app contexts
  appContext: AppContext | BaseAppContext;
}

export const selectorAppContext = (state: State): AppContext => {
  const appContext = state.appContext;
  if (appContext === null || isEmpty(appContext)) {
    throw new Error('AppContext was undefined, it should be defined as soon the application is starting.');
  } else {
    return appContext as AppContext;
  }
};

export const selectorBaseAppContext = (state: State): BaseAppContext => {
  const appContext: BaseAppContext | undefined = state.appContext;
  if (appContext === null || isEmpty(appContext)) {
    throw new Error('BaseAppContext is undefined, it should be defined as soon as an application has started.');
  } else {
    return appContext;
  }
};

export const selectorAppType = (state: State) => selectorAppContext(state).appType;
export const selectorDisplayName = (state: State) => selectorAppContext(state).displayName;
export const selectorFeatures = (state: State) => selectorAppContext(state).features;
export const selectorMarket = (state: State) => selectorAppContext(state).market;
export const selectorLocale = (state: State) => selectorAppContext(state).locale;
